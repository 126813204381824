import { forwardRef, useMemo, useState } from "react"
import styled from "@emotion/styled"
import { v4 as uuidv4 } from "uuid"
import PropTypes from "prop-types"

import { borderRadius, spacing, typography } from "@ninjaone/tokens"
import { getLineHeight, getTextSize, sizer, deprecated, isRequiredIf } from "@ninjaone/utils"

import { localized } from "js/includes/common/utils/ssrAndWebUtils/localization"
import { Flex, Box } from "js/includes/components/Styled"

import { Label } from "./Form/Label"
import InputActions from "./Form/InputActions"
import Body from "./Typography/Body"

function getTextColor({ disabled, readOnly } = {}) {
  if (disabled) return "colorTextDisabled"
  if (readOnly) return "colorTextWeak"
  return "colorTextStrong"
}

function getColors({ theme, disabled, readOnly }) {
  if (disabled)
    return `
      color: ${theme[getTextColor({ disabled, readOnly })]};
      background-color: ${theme.colorBackgroundInputDisabled};
      `
  if (readOnly)
    return `
      color: ${theme[getTextColor({ disabled, readOnly })]};
      background-color: ${theme.colorBackgroundAccentNeutralWeakest};
    `

  return `
    background-color: ${theme.colorBackgroundInput};
  `
}

const StyledInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  height: ${({ isTextArea }) => (isTextArea ? "auto" : "38px")};

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

  font-family: ${typography.fontFamily.primary};
  font-size: ${typography.fontSize.body};

  border: 1px solid
    ${({ disabled, errorMessage, invalid, theme }) =>
      !disabled && (errorMessage || invalid) ? theme.colorBorderError : theme.colorBorderWeak};
  border-radius: ${borderRadius[1]};

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    outline-offset: -2px;
  }

  ${getColors}
`

const StyledPrefix = styled.div`
  padding-left: ${spacing[3]};
  max-width: 20%;
  ${getColors}
`

const StyledSuffix = styled.div`
  padding-right: calc(${({ iconsPaddingValue }) => iconsPaddingValue} + ${spacing[1]});
  max-width: 40%;
  ${getColors}
`

export const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

  margin: 0;
  padding: ${spacing[2]} ${spacing[3]};
  padding-left: ${({ hasPrefix }) => (hasPrefix ? spacing[2] : spacing[3])};
  padding-right: ${({ rightSidePadding }) => rightSidePadding};
  max-width: 980px;

  border: none;
  border-radius: ${borderRadius[1]};

  color: ${({ color, theme }) => (color ? theme.color[color] : theme[getTextColor()])};
  font-size: ${({ size }) => (size ? getTextSize(size) : typography.fontSize.body)};
  font-weight: ${typography.fontWeight.regular};
  font-stretch: normal;
  font-style: normal;
  font-family: ${typography.fontFamily.primary};
  line-height: ${({ size }) => (size ? getLineHeight(size) : typography.lineHeight)};
  letter-spacing: normal;

  user-select: normal;

  ::placeholder {
    color: ${({ theme }) => theme.colorTextWeakest};
  }

  &[type="password"]::-ms-reveal {
    display: none;
  }

  ${getColors}
`

export const StyledLabel = styled.label`
  font-size: ${typography.fontSize.body};
  font-weight: ${typography.fontWeight.regular};
  line-height: ${typography.lineHeight};
  font-family: ${typography.fontFamily.primary};
  color: ${({ theme }) => theme.colorTextStrong};

  padding-bottom: ${spacing[1]};
  margin-bottom: inherit;
`

const getInputType = ({ isSecureInput, isSecureInputVisible, type }) => {
  if (isSecureInput && isSecureInputVisible) return "text"
  if (isSecureInput) return "password"
  return type
}

const Input = forwardRef((props, ref) => {
  const {
    _isTextArea,
    ariaLabel,
    disabled = false,
    readOnly,
    errorMessage,
    isSecureInput,
    id,
    invalid,
    labelText,
    labelToken,
    LabelComponent,
    name,
    onSecureInputVisibilityChange,
    required = false,
    RightSideIcon,
    rightSidePadding,
    secureInputNotVisibleLabel = localized("Show password"),
    secureInputVisibleLabel = localized("Hide password"),
    tooltipText,
    type = "text",
    useCharacterCount = false,
    value,
    maxLength = useCharacterCount ? 75 : null,
    minWidth = "200px",
    prefix,
    suffix,
  } = props
  const label = labelToken ? localized(labelToken) : labelText
  const [isSecureInputVisible, setIsSecureInputVisible] = useState(false)
  const ariaId = useMemo(() => {
    return uuidv4()
  }, [])

  const handleSecureInputVisibility = () => {
    setIsSecureInputVisible(!isSecureInputVisible)
    onSecureInputVisibilityChange?.({ visible: !isSecureInputVisible })
  }

  const stopNumberInputOnWheelChange = e => {
    if (e.target === document.activeElement) {
      e.target.blur()
      e.stopPropagation()
      setTimeout(() => {
        e.target.focus()
      }, 100)
    }
  }

  /*
   * Icon actions are in a WIP state. If you receive a design with input
   * actions please reach out to the UX team in #ask-product-design.
   */
  const renderIcons = () => (
    <InputActions
      {...{
        ariaErrorId: ariaId,
        disabled,
        disableClear: true,
        error: errorMessage,
        RightSideIcon,
        tooltipAlignment: "end",
      }}
      {...(isSecureInput && {
        toggleSecureInputVisibility: handleSecureInputVisibility,
        isSecureInputVisible,
        secureInputNotVisibleLabel,
        secureInputVisibleLabel,
      })}
    />
  )

  const getIconsPaddingValue = () => {
    const standardPadding = 3
    const errorIconPadding = errorMessage ? 7 : 0
    const rightIconPadding = RightSideIcon ? 7 : 0
    const secureIconPadding = isSecureInput ? 7 : 0
    return sizer(standardPadding + errorIconPadding + rightIconPadding + secureIconPadding)
  }

  const hasPrefix = !_isTextArea && Boolean(prefix)
  const hasSuffix = !_isTextArea && Boolean(suffix)
  const iconsPaddingValue = getIconsPaddingValue()

  return (
    <Flex flexDirection="column" maxWidth="980px" {...{ minWidth }}>
      {label && (
        <Label
          labelFor={id || ariaId}
          {...{
            disabled,
            labelText: label,
            required,
            tooltipText,
          }}
        />
      )}

      {LabelComponent && <LabelComponent />}

      <Box width="100%" height={_isTextArea ? "auto" : props.height}>
        <StyledInputContainer
          {...{
            disabled,
            readOnly,
            errorMessage,
            invalid,
            isTextArea: _isTextArea,
          }}
        >
          {hasPrefix && (
            <StyledPrefix aria-hidden {...{ disabled, readOnly }}>
              <Body key={prefix} color={getTextColor({ disabled, readOnly })}>
                {prefix}
              </Body>
            </StyledPrefix>
          )}
          <StyledInput
            ref={ref}
            {...props}
            aria-label={!label ? ariaLabel : undefined}
            aria-disabled={disabled}
            aria-invalid={!!errorMessage || invalid}
            aria-required={required}
            rightSidePadding={rightSidePadding || hasSuffix ? spacing[2] : iconsPaddingValue}
            id={id || ariaId}
            onWheel={type === "number" ? stopNumberInputOnWheelChange : null}
            {...(_isTextArea
              ? { as: "textarea" }
              : { type: getInputType({ isSecureInput, isSecureInputVisible, type }) })}
            {...(isSecureInput && { autoComplete: "new-password" })}
            {...{ disabled, maxLength, name, value, hasPrefix }}
          />
          {hasSuffix && (
            <StyledSuffix aria-hidden {...{ disabled, readOnly, iconsPaddingValue }}>
              <Body key={suffix} color={getTextColor({ disabled, readOnly })}>
                {suffix}
              </Body>
            </StyledSuffix>
          )}
          {renderIcons()}
        </StyledInputContainer>
      </Box>

      {!disabled && useCharacterCount && (
        <Box marginTop={spacing[1]} textAlign="right">
          <Body color={value?.length < maxLength ? "colorTextWeakest" : "colorTextDanger"}>
            {value?.length}/{maxLength}
          </Body>
        </Box>
      )}
    </Flex>
  )
})

export default Input

Input.propTypes = {
  /**
   * Internal prop used by the TextArea component.
   */
  _isTextArea: PropTypes.bool,
  /**
   * The accessible label for the component. Required when `label` is not
   * provided.
   */
  ariaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText") && !props.hasOwnProperty("labelToken"),
    "`ariaLabel` is required when `labelText` and `labelToken` are not defined.",
  ),
  /**
   * Sets the disabled state of the component.
   */
  disabled: PropTypes.bool,
  /**
   * Sets the error state of the component.
   */
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    deprecated(PropTypes.bool, "Use a string value for `errorMessage` instead."),
  ]),
  /**
   * The id of the component.
   */
  id: PropTypes.string,
  /**
   * @deprecated
   * Deprecated prop. Use `errorMessage` prop instead.
   */
  invalid: deprecated(PropTypes.bool, "Use 'errorMessage' prop with a string value instead."),
  /**
   * Determines if the component should render with visibility toggle and
   * `type="password"` to obscure contents.
   */
  isSecureInput: PropTypes.bool,
  /**
   * The text for the Label for the component.
   */
  labelText: PropTypes.string,
  /**
   * The token for the Label for the component.
   */
  labelToken: PropTypes.string,
  /**
   * @deprecated
   * Deprecated prop. Use `labelText` or `labelToken` instead.
   */
  LabelComponent: deprecated(PropTypes.elementType, "Use 'labelText' or 'labelToken' instead."),
  /**
   * The maximum length of the value for the component.
   */
  maxLength: PropTypes.number,
  /**
   * The minimum width of the component in pixels.
   */
  minWidth: PropTypes.string,
  /**
   * Callback run when secure input's visibility changes. Returns the current
   * visibility state of the component's value.
   */
  onSecureInputVisibilityChange: PropTypes.func,
  /**
   * Determines if the component is a read only field.
   */
  readOnly: PropTypes.bool,
  /**
   * Sets the required state of the component.
   */
  required: PropTypes.bool,
  /**
   * The Icon component displayed on the right side of the component.
   */
  RightSideIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  /**
   * The label text for a secure input when component value is obscured.
   */
  secureInputNotVisibleLabel: PropTypes.string,
  /**
   * The label text for a secure input when component value is not obscured.
   */
  secureInputVisibleLabel: PropTypes.string,
  /**
   * The text for the Tooltip in the Label of the the component.
   */
  tooltipText: PropTypes.string,
  /**
   * Determines if the character count should be shown in the component. Used
   * in conjunction with the `maxLength` prop.
   */
  useCharacterCount: PropTypes.bool,
  /**
   * Adds a prefix towards the left of the input component.
   */
  prefix: PropTypes.string,
  /**
   * Adds a suffix towards the right of the input component.
   */
  suffix: PropTypes.string,
}
